import ToastNotification from "../utils/ToastNotification";

export default class ConfigurationChangeEmail {
    constructor() {
        this.validation = null;

        $.validator.addMethod(
            "uniqueEmail",
            function(value, element) {
                let response = $.ajax({
                    type: "GET",
                    url: window.application.getAPIUrl()+"/v1/users/check_email",
                    data: {
                        email: value
                    },
                    async: false,
                    beforeSend: function (request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    }
                });

                return response.responseJSON.exists !== true
            },
            function (params, element) {
              return I18n.t("admins.registrations.new.email_taken", {
                email: $(element).val(),
              });
            }
        );

        this.validation_options = {
            rules:{
                "user[email]": {
                    email: true,
                    required: true,
                    uniqueEmail: true
                },
                "user[email_current_password]": {
                    required: true
                }
            }
        }
        this.bindEvents();
    }

    bindEvents() {
        let self = this;
        $("#change_email").unbind("click").click(function (e) {
            e.preventDefault();
            self.changeEmail();
        });
    }

    onDataChanged(data) {
        if(I18n.locale !== 'en'){
            this.validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale)
        }
        this.validation = $("#email_change").validate(this.validation_options);
    }

    onDestroy() {
        $("#change_email").unbind("click");
        this.validation.destroy();
    }

    changeEmail() {
        if($("#user_email").val() !== $("#current_email").val() && this.validation.form()){
            let self = this;
            let data = {
                email: $("#user_email").val(),
                current_password: $("#user_email_current_password").val(),
            };

            let ajaxData = {
                url: window.application.getAPIUrl() + "/v1/profile/update_confirmable",
                data: {
                    user: data
                },
                method: "PUT",
                enctype: 'multipart/form-data',
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };

            $.ajax(ajaxData).done(function (data) {
                ToastNotification.make(I18n.t("website.configuration.index.message.email_changed"));
                $("#current_email").val($("#user_email").val());
                $("#user_email_current_password").val("");
            }).fail(function (err) {
                ToastNotification.make(err.responseJSON.errors.join("<br>"));
            });
        }
    }
}