import ToastNotification from "../utils/ToastNotification";
import "trix";
import "trix/dist/trix.css";

export default class ConfigurationProfile {
    constructor() {
        this.dropArea = document.getElementById('edit_thumbnail');
        this.file = null;
        this.size_in_megas = 1;
        this.validation = null;
        this.validation_username = null;
        this.validation_user_description = null;
        this.timeout = null;

        $.validator.addMethod("alphanumeric_no_white", function(value, element) {
            return this.optional(element) || /^([\p{L}0-9_\-]{4,127}$)/u.test(value);
        }, I18n.t("admins.registrations.new.username_invalid"));

        $.validator.addMethod("uniqueUserName", function(value, element) {
            if(value !== $("#current_user_name").val()){
                let response = $.ajax({
                    type: "GET",
                    url: window.application.getAPIUrl()+"/v1/users/check_user_name",
                    data: {username: value},
                    async: false,
                    beforeSend: function (request) {
                        request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                    }
                });

                return response.responseJSON.exists !== true;
            } else {
                return true;
            }
        }, I18n.t("admins.registrations.new.username_taken"));

        // Ajouter les méthodes de validation personnalisées
        $.validator.addMethod("validEmail", function(value, element) {
            return this.optional(element) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        }, "Please enter a valid email address.");

        $.validator.addMethod("validUrl", function(value, element) {
            return this.optional(element) || /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value);
        }, "Please enter a valid URL.");

        this.validation_options = {
            rules: {
                "user[password]": {
                    minlength: 8,
                    required: true
                },
                "user[password_confirmation]": {
                    required: true,
                    equalTo: "#user_password"
                },
                "user[current_password]": {
                    required: true
                }
            }
        };

        this.validation_user_description_options = {
            onkeyup: true, // Change to true to validate on keyup
            rules: {
                "public_email": {
                    validEmail: true
                },
                "url": {
                    validUrl: true
                }
            }
        };

        this.validation_username_options = {
            onkeyup: false,
            rules: {
                "user_name": {
                    required: true,
                    alphanumeric_no_white: true,
                    uniqueUserName: true
                }
            }
        };

        this.toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        this.bindEvents();

        window.addEventListener('beforeunload', () => this.onDestroy());
    }

    bindEvents() {
        let self = this;

        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            self.dropArea.addEventListener(eventName, self.preventDefaults, false);
        });

        this.dropArea.addEventListener('drop', function (event) {
            self.handleDrop(event);
        }, false);

        $("#thumbnail").change(function () {
            self.handleFiles(this.files);
        });

        window.$('#js-country-select').select2({
            theme: 'bootstrap4',
            templateResult: this.formatActivity,
            templateSelection: this.formatActivity,
            language: I18n.locale
        });

        window.$('#js-lang-select').select2({
            theme: 'bootstrap4',
            templateResult: this.formatLang,
            templateSelection: this.formatLang,
            language: I18n.locale
        });

        $('b[role="presentation"]').hide();
        $('.select2-selection__arrow').append('<i class="fas fa-chevron-down fs-12"></i>');
        $('.select2-selection__arrow').addClass("custom_arrow_select2");

        $(".send-profile-description").unbind("click").click(function (e) {
            e.preventDefault();
            self.sendForm();
        });

        $(".send-profile").unbind("click").click(function (e) {
            e.preventDefault();
            self.sendForm();
        });

        $("#public_email, #url").on('blur keyup', function () {
            $(this).valid();
        });

        document.addEventListener("trix-file-accept", function(event) {
            event.preventDefault();
        });

        document.addEventListener("trix-initialize", function(event) {
            var editor = event.target;
            editor.addEventListener("drop", function(e) {
                e.preventDefault();
            });
            editor.addEventListener("dragover", function(e) {
                e.preventDefault();
            });
        });
    }

    onDataChanged(data) {
        let self = this;
        this.stripe = Stripe(data.stripe_public_key);

        $("#date").datepicker({
            autoClose: true,
            startDate: "-80Y",
            endDate: "0D"
        });

        if(I18n.locale !== 'en'){
            this.validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
            this.validation_username_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }
        this.validation = $("#password_change").validate(this.validation_options);

        this.validation_username = $("#profile_form").validate(this.validation_username_options);
        
        if ($("#profile_description_form").length) {
            this.validation_user_description = $("#profile_description_form").validate(this.validation_user_description_options);
        }

        $("#user_name").off("keyup").on("keyup", function (e){
            clearTimeout(self.timeout);
            self.timeout = setTimeout(function () {
                self.validation_username.form();
            }, 500);
        });
    }

    onDestroy() {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            $(this.dropArea).off(eventName);
        });
        $(this.dropArea).off("drop");
        $("#date").datepicker("destroy");
        window.$('#js-country-select').select2('destroy');
        window.$('#js-lang-select').select2('destroy');
        this.validation.destroy();
        this.validation_username.destroy();
        if (this.validation_user_description) {
            this.validation_user_description.destroy();
        }
    }

    preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop(e) {
        let dt = e.dataTransfer;
        let files = dt.files;
        if(this.isFileImage(files[0])){
            this.correctFormat();
            this.handleFiles(files);
        } else {
            this.wrongFormat();
        }
    }

    handleFiles(files) {
        if(files[0].size > (1024*1024*this.size_in_megas)){
            this.wrongFormat();
        } else {
            this.previewFile(files[0]);
        }
        $("#thumbnail").val("");
    }

    isFileImage(file) {
        return file && file['type'].split('/')[0] === 'image';
    }

    previewFile(file) {
        let reader = new FileReader();

        reader.onload = function(e) {
            $('#thumbnail_img').css('background-image', 'url('+e.target.result+')');
        }
        this.file = file;
        reader.readAsDataURL(file); // convert to base64 string
    }

    formatActivity(act) {
        let $act = "";
        if (!act.id) {
            return act.name;
        } else if($(act.element).val() === "0"){
            $act = $(
                '<span class="lh-7 fs-16 px-2">' + act.text + '</span>'
            );
        } else {
            $act = $(
                '<span class="lh-7 fs-16">\n<span class="mx-2 rounded flag-icon flag-icon-'+ $(act.element).data("country-code") +'"></span>' + act.text + '</span>'
            );
        }
        return $act;
    }

    formatLang(act) {
        let $act = "";
        if (!act.id) {
            return act.name;
        } else {
            $act = $(
                '<span class="lh-7 fs-16 px-2">' + act.text + '</span>'
            );
        }
        return $act;
    }

    async sendForm() {
        let isValid = true;
        if (this.validation_username) {
            isValid = isValid && this.validation_username.form();
        }
        if (this.validation_user_description) {
            isValid = isValid && this.validation_user_description.form();
        }

        if (isValid) {
            const data = {
                first_name: $("#first_name").val(),
                user_name: $("#user_name").val(),
                last_name: $("#last_name").val(),
                birth_at: $("#date").val(),
                country_id: $("#js-country-select").val(),
                language: $("#js-lang-select").val(),
                city: $("#city").val(),
                gender: $("input[name='gender']:checked").val(),
                user_profile_description_attributes: {
                    description: $("#description").val(),
                    privacy_type: $("#privacy_type").val(),
                    public_email: $("#public_email").val(),
                    phone_number: $("#phone_number").val(),
                    url: $("#url").val()
                }
            };

            if (this.file !== null) {
                data.picture = await this.toBase64(this.file);
            }

            const ajaxData = {
                url: window.application.getAPIUrl() + "/v1/profile/",
                data: {
                    user: data
                },
                method: "PUT",
                enctype: 'multipart/form-data',
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };

            $.ajax(ajaxData).done(function (data) {
                $("#user_profile_link").attr("href", window.location.protocol + "//" + window.location.host + "/user/" + $("#user_name").val());
                $("#current_user_name").val($("#user_name").val());
                ToastNotification.make(I18n.t("website.configuration.index.message.save_profile"));
            }).fail(function (err) {
                ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
            });
        }
    }

    async changeRepeatTime() {
        const data = {
            repeat_time_setting: $("input[name='repeat_time']:checked").val()
        };

        const ajaxData = {
            url: window.application.getAPIUrl() + "/v1/profile/update_repeat_time/",
            data: {
                user: data
            },
            method: "PUT",
            enctype: 'multipart/form-data',
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        $.ajax(ajaxData).done(function (data) {
            ToastNotification.make(I18n.t("website.configuration.index.message.save_profile"));
        }).fail(function (err) {
            ToastNotification.make(I18n.t("website.configuration.index.message.save_ko"));
        });
    }

    wrongFormat() {
        $("#file_format").removeClass("text-light-gray").addClass("text-danger");
    }
    
    correctFormat() {
        $("#file_format").removeClass("text-danger").addClass("text-light-gray");
    }

    changePassword() {
        if($("#user_password").val() !== "" && this.validation.form()){
            let self = this;
            let data = {
                password: $("#user_password").val(),
                password_confirmation: $("#user_password_confirmation").val(),
                current_password: $("#user_current_password").val(),
            };

            let ajaxData = {
                url: window.application.getAPIUrl() + "/v1/profile/update_confirmable",
                data: {
                    user: data
                },
                method: "PUT",
                enctype: 'multipart/form-data',
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            };

            $.ajax(ajaxData).done(function (data) {
                ToastNotification.make(I18n.t("website.configuration.index.message.password_changed"));
                $("#user_password").val("");
                $("#user_password_confirmation").val("");
                $("#user_current_password").val("");
            }).fail(function (err) {
                ToastNotification.make(err.responseJSON.errors.join("<br>"));
            });
        }
    }
}
