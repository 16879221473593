import ToastNotification from "../utils/ToastNotification";
import UploadFilesController from "../controllers/UploadFilesController";
import ConfirmationModal from "../../common/ConfirmationModal";
export default class EditTimelineItem {

    constructor() {
        this.categories = null;
        this.bindEvents();
        this.confirmationModal = new ConfirmationModal();
    }

    bindEvents() {
        let self = this;

        $(".js-edit-timeline-item").unbind("click").click(function (e){
            e.preventDefault();
            self.buildModal(
                $(this).data("type"),
                $(this).data("id"),
                $(this).data("locality-id"),
                $(this).data("locality-name"),
                $(this).data("category-id"),
                ($(this).data("name") !== undefined ? $(this).data("name") : ""),
                $(this).data("view")
            );

        })
        self.bindDeleteImageEvents();
    }

    bindDeleteImageEvents() {
        let self = this;
        $(".track_images_container .action").unbind("click").click(function (e) {
            e.preventDefault();
            const id = $(this).attr("id");
            const trackId = $(this).attr("data-track-id");

            self.confirmationModal.displayConfirm(I18n.t("website.track.delete_image.title"),
                I18n.t("website.track.delete_image.message"),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("website.track.delete_image.positive_btn"),
                        callback: function () {
                            self.deleteImage(id, trackId);
                        }
                    },
                    negative_btn: {
                        label: I18n.t("website.track.delete_image.negative_btn")
                    }
                });

        })
    }

    deleteImage(id, trackId) {

        let ajaxData = {
            url: `${window.application.getAPIUrl()}/v1/track/${trackId}/images`,
            data: { file_id: id },
            method: "DELETE",
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };
        $.ajax(ajaxData).done(function (data) {
            $(`#image_${id}`).remove();
        }).fail(function (err) {
        });
    }
    onDataChanged(data) {
        this.categories = data.categories;
    }

    onDestroy() {
        $(".js-edit-timeline-item").unbind("click");
    }

    reloadEvents(){
        this.bindEvents();
    }
    async getTrackModal(track_id) {
        let ajaxData = {
            url: window.application.getAPIUrl() + "/v1/track/" + track_id,
            method: "GET",
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        const data = await $.ajax(ajaxData);
        
        this.fillTrackModalData(data);
        this.fileManager = new UploadFilesController();
        
        return $(this.getModalId('track')).clone();
    }

    getModalId(type) {
         return type == 'track'? '#edit_track_item_modal' : '#edit_scene_item_modal';
    };

    fillTrackModalData(data) {
        this.setTrackModelCategory(data);
        this.setTrackModelTags(data);
        this.setTrackModelFeatures(data);
    }
    setTrackModelTags(data) {
        const tagsSelector = $("#edit_track_tags").empty();
        data.tags && data.tags.forEach(function (tag) {
            const option = '<option value="' + tag.name + '" selected >' + tag.name + '</option>';
            tagsSelector.append(option);
        });
    }
    setTrackModelFeatures(data) {
        const featuresSelector = $("#edit_track_stuff").empty();
        data.features && data.features.forEach(function (feature) {
            const option = '<option value="' + feature.name + '" selected >' + feature.name + '</option>';
            featuresSelector.append(option);
        });
    }
    setTrackModelCategory(data) {
        const track = data.track;
        const category = $("#edit_timeline_category").empty();
        this.categories.forEach(function (item) {
            const option = '<option value="' + item.id + '" data-icon="' + item.icon_class + '" data-description="' + item.description + '" ' + (parseInt(item.id) === parseInt(track.category_id) ? "selected" : "") + '>' + I18n.t("track_categories." + item.name) + '</option>';
            category.append(option);
        });
    }
    async buildModal(type, track_id, locality_id, locality_name, category_id, name = "", viewType) {

        let self = this;
        const isTrack = type == 'track';
        const content = isTrack ? await self.getTrackModal(track_id)
            : this.getSceneEditModal(category_id, name);

        $("body").append(content);
        
        $(self.getModalId(type)).on("shown.bs.modal", function () {
            self.setSelect2(type, track_id, locality_id, locality_name);
            $("#save_timeline_item",$(self.getModalId(type))).unbind("click").click(function (e) {
                e.preventDefault();
                const disabled = $(this).hasClass("disabled");
                if (disabled) {
                    return;
                }
                $(this).addClass("disabled");
                self.updateTimelineItem(type, track_id, viewType);
            })

            window.$('[data-toggle="tooltip"]').tlp();
        });
        $(self.getModalId(type)).modal("show");
        $(self.getModalId(type)).on("hidden.bs.modal", function () {
            $(self.getModalId(type)).remove();
            if (isTrack) {
                self.fileManager.stopAllUploading();
            }
        });
        if (isTrack) {
            self.initTags();
            self.initFeatures();
            self.fileManager.init(               
                {
                    uploadAPI: {
                        url: `${window.application.getAPIUrl()}/v1/track/${track_id}/images`
                },
                    deleteAPI: {
                        url: `${window.application.getAPIUrl()}/v1/track/${track_id}/images`
                    }
                },
                '#save_timeline_item',
                "edit_track",
                {
                    mimeFileTypes: ['image/jpeg', 'image/png'],
                    fileExt: ['jpeg', 'jpg', 'png'],
                    size: 3500
                }, 2);
    }
    }

    getSceneEditModal(category_id, name = "") {
        let modal = '<div id="edit_scene_item_modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true" style="display: none;">\n' +
            '        <div class="modal-dialog modal-dialog-centered">\n' +
            '            <div class="modal-content">\n' +
            '                <div class="modal-header py-1 bg-dark-light">\n' +
            '                  <div class="row w-100">\n' +
            '                    <div class="col-1 my-auto">\n' +
            '                      <img height="36" class="mr-3" src="'+ window.badge_outline_url +'">\n' +
            '                    </div>\n' +
            '                    <div class="col-9 my-auto">\n' +
            '                      <h4 class="modal-title text-white fs-16" id="confirmModalLabel">\n' +
            '                        ' + I18n.t("website.user.show.timeline_item.modal.scene.title") +
            '                      </h4>\n' +
            '                    </div>\n' +
            '                  </div>\n' +
            '                </div>\n' +
            '                <div class="modal-body">\n' +
            '                   <div class="row">' +
            '                       <div class="col-12 col-lg-6 select-image select2-w-100">\n' +
            '                           <label class="fs-14" data-toggle="tooltip" data-placement="right" title="' + I18n.t("website.user.show.timeline_item.modal.scene.category_advice") + '">' + I18n.t("website.user.show.timeline_item.modal.category") + ' <i class="text-danger fas fa-exclamation-triangle"></i></label>' +
            '                           <select id="edit_timeline_category" class="">';
                                            this.categories.forEach(function (item){
                                                modal+= '<option value="'+ item.id + '"';
                                                modal+= ' data-icon="'+ item.icon_class + '"';
                                                modal+= ' data-description="'+ item.description + '"';
                                                modal+= (parseInt(item.id) === parseInt(category_id) ? " selected" : "");
                                                modal+= '>'+ I18n.t("track_categories." + item.name);
                                                modal+= '</option>';
                                            })
        modal += '                      </select>' +
        '                           </div>' +
            '                       <div class="col-12 col-lg-6 mt-3 select-locality mt-lg-0 select2-w-100">' +
            '                           <label class="fs-14">'+ I18n.t("website.user.show.timeline_item.modal.locality") +'</label>' +
            '                           <select id="edit_timeline_locality" class="">' +
            '                           </select>' +
            '                       </div>';

            modal += '                       <div class="col-12 col-lg-6 mt-3 select2-w-100">' +
                '                           <label class="fs-14">'+ I18n.t("website.user.show.timeline_item.modal.scene.name") +'</label>' +
                '                           <input id="edit_timeline_name" type="text" class="form-control" value="'+name+'">' +
                '                       </div>';

        modal +='                       </div>\n' +
        '                </div>\n' +
        '                <div class="modal-footer">\n' +
        '                  <button class="btn btn-outline-secondary" data-dismiss="modal">'+ I18n.t("website.layout.modals.cancel") +'</button>\n' +
        '                  <button id="save_timeline_item" class="btn btn-secondary">'+ I18n.t("website.layout.modals.save") +'</button>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>';
        return modal
    }

    setSelect2(type, track_id, locality_id, locality_name) {
        let self = this;
        const currentModal = $(this.getModalId(type));
        $('#edit_timeline_locality',currentModal).select2({
            theme: 'bootstrap4',
            language: I18n.locale,
            tokenSeparators: [","],
            tags: true,
            ajax:{
                delay: 250,
                url: window.application.getAPIUrl()+"/v1/users/"+track_id+"/localities",
                data: function (params) {
                    return {
                        type: type,
                        search: params.term,
                        page: params.page || 1
                    };
                },
                beforeSend: function(request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: $.map(data.results, function(obj) {
                            return {
                                "id": obj.id,
                                "text": obj.text
                            };
                        }),
                        pagination: {
                            more: (params.page * 10) < data.count_filtered
                        }
                    };
                },
                transport: function (params, success, failure) {
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            }
        });

        let option = new Option(locality_name, locality_id, true, true);
        $('#edit_timeline_locality',currentModal).append(option).trigger('change');
        $('#edit_timeline_locality',currentModal).trigger({
            type: 'select2:select',
            params: {
            }
        });

        $('#edit_timeline_category',currentModal).select2({
            theme: 'bootstrap4',
            language: I18n.locale,
            templateResult: window.application.formatCategory,
            templateSelection: window.application.formatCategory,
        });
        $('.select2-w-100 .select2').css("width", "100%")
    }

    updateTimelineItem(type, track_id, viewType) {
        const currentModal = $(this.getModalId(type));
        const self = this;
        let locality_id = $("#edit_timeline_locality",currentModal).val();
        let category_id = $("#edit_timeline_category",currentModal).val();
        let name = (type === 'scene') ? $("#edit_timeline_name",currentModal).val() : "";
        const tags = type === 'track' ? $("#edit_track_tags",currentModal).val() : [];
        const features = type === 'track' ? $("#edit_track_stuff", currentModal).val() : [];        
        const data = {
            type: type,
            locality_id : locality_id,
            category_id : category_id
        }; 

        if (type === 'scene') {
            data.name = name;
        }
        if (type === 'track') {
            data.tags = tags;
            data.features = features;
        }

        let ajaxData = {
            url: window.application.getAPIUrl() + "/v1/users/"+track_id+"/update_"+type,
            method: "PUT",
            data: data,
            beforeSend: function(request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
            success: function (data){
                $("#edit_timeline_item_modal").modal("hide");
                if(data.action === "remove"){                    
                    $(self.getModalId(type)).modal("hide");
                    $("."+ data.type +"_item[data-id='"+data.track_id+"']").hide("slow");
                    ToastNotification.make(I18n.t("website.user.show.timeline_item.modal."+data.type+".on_process"));
                } else {
                    // $('#'+data.type+'_locality_url_'+data.object.id).attr("href", window.location.origin + "/localities/"+data.locality_country_code+"/"+data.locality_id);
                    if(data.type === "scene"){
                        data.object_id = data.scene_id;
                    } else {
                        data.object_id = data.track_id;
                    }
                    $('#'+ data.type +'_locality_name_'+ data.object_id).text(data.locality_name);
                    $('#'+ data.type +'_locality_url_'+ data.object_id).attr("href", data.locality_url);
                    $('#'+ data.type +'_category_name_'+ data.object_id).text(data.category_name);

                    $(self.getModalId(type)).modal("hide");
                    
                    ToastNotification.make(I18n.t("website.user.show.timeline_item.modal."+data.type+".saved"));
                    self.renderTrackImages(track_id, data.newAttached, viewType, () => {
                        $("#save_timeline_item", $(self.getModalId(type))).removeClass('disabled');
                    })
                }
            },
            error: function (err){
            console.log(err);
                $("#save_timeline_item", $(self.getModalId(type))).removeClass('disabled');
            }
        }

        $.ajax(ajaxData)
    }
    renderTrackImages(trackId, ids, viewType, callback) {
        if (!trackId || !ids || ids.length < 1) {
            callback && callback();
            return;
        }
        let self = this;

        $.ajax({
            url: `/track/${trackId}/ajax_image_items`,
            type: "get",
            data: {
                ids: ids,
                landscape: viewType == 'single'
            },
            success: function (data) {

                $(`#track_${trackId}_images`).append(data);
                self.bindEvents();
                callback && callback();
            },
            error: function (data) {
                callback && callback();
            }
        });
    }
    initTags() {
        $('#edit_track_tags').select2({
            theme: 'bootstrap4',
            language: I18n.locale,
            tags: true,
            tokenSeparators: [",", ';'],
            ajax: {
                delay: 250,
                url: window.application.getAPIUrl() + "/v1/users/current_tags",
                data: function (params) {
                    let requestData = {
                        search: params.term,
                        category_id: $("#edit_timeline_category").val(),
                        page: params.page || 1
                    };
    
                    if (window.application.host_used == "birdtracking") {
                        requestData.filter_scientific = true;
                    }

                    return requestData;
                },
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: $.map(data.results, function (obj) {
                            return {
                                "id": obj.text,
                                "text": obj.text
                            };
                        }),
                        pagination: {
                            more: (params.page * 10) < data.count_filtered
                        }
                    };
                },
                transport: function (params, success, failure) {
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            }
        });
    }
    initFeatures() {
        $('#edit_track_stuff', $(this.getModalId('track'))).select2({
            theme: 'bootstrap4',
            language: I18n.locale,
            tags: true,
            tokenSeparators: [",", ';'],
            ajax:{
                delay: 250,
                url: window.application.getAPIUrl()+"/v1/users/current_features",
                data: function (params) {
                    let requestData = {
                        search: params.term,
                        category_id: $("#edit_timeline_category").val(),
                        page: params.page || 1
                    };
    
                    if (window.application.host_used == "birdtracking") {
                        requestData.filter_scientific = true;
                    }

                    return requestData;
                },
                beforeSend: function(request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: $.map(data.results, function(obj) {
                            return {
                                "id": obj.text,
                                "text": obj.text
                            };
                        }),
                        pagination: {
                            more: (params.page * 10) < data.count_filtered
                        }
                    };
                },
                transport: function (params, success, failure) {
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            }
        });
    }
}