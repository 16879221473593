import HomeLiveMap from "../views/HomeLiveMap";
import HomeLiveEvents from "../views/HomeLiveEvents";
import HomeLiveStandings from "../views/HomeLiveStandings";

export default class LocalitiesIndex {
  constructor() {
    this.homeLiveMap = new HomeLiveMap();
    this.HomeLiveEvents = new HomeLiveEvents();
    this.homeLiveStandings = new HomeLiveStandings();
    this.updateIntervalId = null;

    this.selected_tracks = [];
    this.following_users = [];

    this.category_id = 0;
    this.from_type = 0;
    this.bindEvents();
    window.application.setOnDataChangeListener(this);
  }

  bindEvents() {
    const self = this;

    window.$("#js-activity-live-select").select2({
      theme: "bootstrap4",
      templateResult: this.formatActivity,
      templateSelection: this.formatActivity,
      language: I18n.locale,
    });

    $('b[role="presentation"]').hide();
    $(".select2-selection__arrow").append('<i class="fas fa-chevron-down fs-12"></i>');
    $(".select2-selection__arrow").addClass("home_custom_arrow_select2");

    this.homeLiveMap.map.on("moveend", () => {
      this.homeLiveMap.updateMapTracks();
      this.homeLiveStandings.updateBounds(this.homeLiveMap.map.getBounds());
      this.HomeLiveEvents.loadEventsOnBounds(this.homeLiveMap.map.getBounds());
      this.setPositionCookie();
    });

    $("#js-activity-live-select").on("change", function () {
      self.updateCategory($(this).val());
    });

    $(".js-filter-type")
      .off("click")
      .on("click", function (e) {
        e.preventDefault();
        $("#filter-type-btn").text($(this).data("name-hr"));
        self.updateEventsFrom($(this).data("value"));
      });

    $(document)
      .off("socket-LiveChannel")
      .on("socket-LiveChannel", (e, data) => {
        this.onSocketMessageReceived(data);
      });

    window.application.socket_handler.subscribe({
      channel: "LiveChannel",
      event: "new-live",
    });
    window.application.socket_handler.subscribe({
      channel: "LiveChannel",
      event: "tracker-update",
    });
    window.application.socket_handler.subscribe({
      channel: "LiveChannel",
      event: "new-track",
    });
    window.application.socket_handler.subscribe({
      channel: "LiveChannel",
      event: "track_end",
    });

    // window.addEventListener('offline', (event) => {});

    window.addEventListener("online", (event) => {
      window.application.socket_handler.subscribe({
        channel: "LiveChannel",
        event: "new-live",
      });
      window.application.socket_handler.subscribe({
        channel: "LiveChannel",
        event: "tracker-update",
      });
      window.application.socket_handler.subscribe({
        channel: "LiveChannel",
        event: "new-track",
      });
      window.application.socket_handler.subscribe({
        channel: "LiveChannel",
        event: "track_end",
      });
      this.following_users.forEach(function (item) {
        this.follow(item);
      });
    });

    this.live_btn_listener();
    this.addTimeoutTracksUpdating();
    this.setFatherClass();
  }

  addTimeoutTracksUpdating() {
    const updatingIntervalMS = 1000 * 30; 
    const startInterval = () => {
      this.updateIntervalId = setTimeout(() => {
        update();
      }, updatingIntervalMS);
    };
    
    const update = () => { 
      this.homeLiveMap.updateMapTracks();
      this.homeLiveStandings.updateBounds(this.homeLiveMap.map.getBounds());
      this.HomeLiveEvents.loadEventsOnBounds(this.homeLiveMap.map.getBounds());
      this.HomeLiveEvents.loadLiveEventsOnBounds();
      startInterval();
    };

    startInterval();
  }

  onDataChanged(data) {
    this.homeLiveMap.onDataChanged(data);
    this.HomeLiveEvents.onDataChanged(data);
    this.homeLiveStandings.updateBounds(this.homeLiveMap.map.getBounds());
    this.HomeLiveEvents.loadEventsOnBounds(this.homeLiveMap.map.getBounds());
  }

  onDestroy() {
    window.$("#js-activity-live-select").select2("destroy");
    $("#js-activity-live-select").off("change");
    $("#js-filter-type").off("click");
    this.reset_live();
    this.homeLiveMap.onDestroy();
    this.HomeLiveEvents.onDestroy();
    this.homeLiveStandings.onDestroy();
    $(document).off("socket-LiveChannel");
    window.application.socket_handler.unsubscribe({
      channel: "LiveChannel",
      event: "new-live",
    });
    clearInterval(this.updateIntervalId);
  }

  formatActivity(act) {
    let $act = "";
    if (!act.id) {
      return act.name;
    } else if ($(act.element).val() === "0") {
      $act = $('<span class="mt-2 d-block">' + act.text + "</span>");
    } else {
      $act = $(
        '<span class="lh-5">\n<span class="bg-secondary dot mr-2 fs-20"><i class="text-white icon icon-' +
          $(act.element).data("icon") +
          '"></i></span>' +
          act.text +
          "</span>"
      );
    }
    return $act;
  }

  updateCategory(category_id) {
    //this.reset_live();
    this.category_id = category_id;
    //this.selected_tracks = [];
    this.homeLiveMap.updateCategory(category_id);
    this.HomeLiveEvents.updateCategory(category_id);
    this.homeLiveStandings.updateCategory(category_id);
  }

  updateEventsFrom(from_type) {
    //this.reset_live();
    this.from_type = from_type;
    //this.selected_tracks = [];
    this.homeLiveMap.updateEventsFrom(this.from_type);
    this.HomeLiveEvents.updateEventsFrom(this.from_type);
    this.homeLiveStandings.updateEventsFrom(this.from_type);
  }

  follow(user_id) {
    if (!this.following_users.includes(user_id)) {
      this.following_users.push(user_id);
    }
    window.application.socket_handler.subscribe({
      channel: "LiveChannel",
      id: user_id,
    });
  }

  unfollow(user_id) {
    this.following_users.splice(this.following_users.indexOf(user_id), 1);
    window.application.socket_handler.unsubscribe({
      channel: "LiveChannel",
      id: user_id,
    });
  }

  onSocketMessageReceived(data) {
    const eventHandlers = {
      "point-add": this.onPointAdd.bind(this),
      "tracker-update": this.onTrackerUpdate.bind(this),
      "new-live": this.onNewTrack.bind(this),
      "new-track": this.onNewTrack.bind(this),
      "track-end": this.onTrackEnd.bind(this),
    };

    const handler = eventHandlers[data.e];
    handler && handler(data);
  }

  onTrackerUpdate(data) {
    this.homeLiveMap.update_live_markers(data);
    this.HomeLiveEvents.update_live_events(data);
  }

  onPointAdd(data) {
    this.homeLiveMap.update_live_markers(data);
    this.HomeLiveEvents.update_live_events(data);
  }

  onNewTrack(data) {
    if (data.e === "new-live") {
      const count_lives = $("#live-counter").data("lives") + 1;
      $("#live-counter").data("lives", count_lives);
      $("#live-counter").text(count_lives);
    }
    this.HomeLiveEvents.loadEventsOnBounds(this.homeLiveMap.map.getBounds());
    this.homeLiveMap.updateMapTracks();
  }

  onTrackEnd(data) {
    if ($("#live-counter").data("lives") > 0) {
      const count_lives = $("#live-counter").data("lives") - 1;
      $("#live-counter").data("lives", count_lives);
      $("#live-counter").text(count_lives);
    }
    this.HomeLiveEvents.loadEventsOnBounds(this.homeLiveMap.map.getBounds());
    this.homeLiveMap.updateMapTracks();

    const track = this.selected_tracks.filter((x) => data.tid === x);
    if (track.length > 0) {
      this.HomeLiveEvents.live_ended(data.tid);
      this.homeLiveMap.live_ended(data.tid);
    }
  }

  setFatherClass() {
    this.homeLiveMap.setFatherClass(this);
    this.HomeLiveEvents.setFatherClass(this);
    this.homeLiveStandings.setFatherClass(this);
  }

  live_btn_listener() {
    let self = this;
    $(".toggle_live")
      .off("click")
      .on("click", function (e) {
        e.preventDefault();
        self.toggle_track_live(parseInt($(this).data("tid")), parseInt($(this).data("uid")), $(this).data("live"));
      });
    $(".center_live")
      .off("click")
      .on("click", function (e) {
        e.preventDefault();
        self.center_track_live(parseInt($(this).data("tid")), $(this).data("live"));
      });
  }

  toggle_track_live(tid, uid, is_live) {
    let track = this.selected_tracks.filter((x) => tid === x);

    if (track.length === 0) {
      this.follow(uid);
      this.enable_track_live(tid, uid);
    } else {
      this.unfollow(uid);
      this.disable_track_live(tid);
    }
  }

  center_track_live(tid, is_live) {
    this.homeLiveMap.center_to(tid, is_live);
  }

  disable_track_live(tid) {
    this.selected_tracks.splice(this.selected_tracks.indexOf(tid), 1);
    let s_tracks = [...this.selected_tracks];
    this.HomeLiveEvents.disable_track_live(tid, s_tracks);
    this.homeLiveMap.disable_track_live(tid, s_tracks);
    this.homeLiveStandings.set_tracks_live(s_tracks);
  }

  enable_track_live(tid, uid) {
    this.selected_tracks.push(tid);
    let s_tracks = [...this.selected_tracks];
    this.HomeLiveEvents.enable_track_live(tid, s_tracks);
    this.homeLiveMap.enable_track_live(tid, s_tracks, uid);
    this.homeLiveStandings.set_tracks_live(s_tracks);
  }

  reset_live() {
    let self = this;
    $("#list_selected_live_items").empty();
    this.following_users.forEach(function (item) {
      self.unfollow(item);
    });
    this.selected_tracks.forEach(function (item) {
      self.disable_track_live(item);
    });
  }

  setPositionCookie() {
    let bounds = this.homeLiveMap.map.getBounds();
    let x = (bounds._southWest.lat + bounds._northEast.lat) / 2;
    let y = (bounds._southWest.lng + bounds._northEast.lng) / 2;
    let z = this.homeLiveMap.map.getZoom();
    cookies.create("home_map_lat", x);
    cookies.create("home_map_lng", y);
    cookies.create("home_map_zoom", z);
  }
}
