import I18n from "i18n-js";
import ListTrack from "../views/ListTrack.js"

const FILTER_KEYS = Object.freeze({
    tags: "filter_tags[]",
    features: "filter_features[]",
    categories: "filter_categories[]",
    startYear: "start_period_year",
    startMonth: "start_period_month",
    endYear: "end_period_year",
    endMonth: "end_period_month",
});

export default class UserStats {

    constructor() {
        this.listTrack = new ListTrack();
        this.listTrack.setFatherClass(this);
        this.userId = null;
        this.filterOptions = {
            tags: [],
            features: [],
            categories: [],
            startYear: null,    
            startMonth: null,
            endYear: null,
            endMonth: null,
        }

        window.application.setOnDataChangeListener(this);
    }

    onDataChanged(data) {
        this.userId = data.user_id;

        $(() => {
            this.initSelect2();
            this.initDatepickers();
            this.bindEvents();
            this.setDefaultValuesToFilters();
        });
    }

    onDestroy() {
        $(".js-select2-multiple-tags-filter").select2("destroy");
        $(".js-select2-multiple-features-filter").select2("destroy");

        $("#start_date").datepicker("destroy");
        $("#end_date").datepicker("destroy");
    }

    bindEvents() {
        $(".js-filter-activity").off("click").on("click", (event) => {
            event.preventDefault();
            this.updateCategoryFilter($(event.currentTarget).data("id"), $(event.currentTarget).data("name-hr"));
        });

        $("#filter_form").off("submit").on("submit", (e) => {
            e.preventDefault();
            this.onFilterSubmit();
        });

        const onTagsChange = () => {
            this.filterOptions.tags = $("#filter_track_tags").select2("data").map(data => data.id);
            this.allowFilterApplying();
        }

        const onFeaturesChange = () => {
            this.filterOptions.features = $("#filter_track_features").select2("data").map(data => data.id);
            this.allowFilterApplying();
        }

        $("#filter_track_tags").on("select2:select", onTagsChange).on("select2:unselect", onTagsChange);
        $("#filter_track_features").on("select2:select", onFeaturesChange).on("select2:unselect", onFeaturesChange);

        $("#start_date").datepicker().off("changeDate").on("changeDate", (event) => {
            this.allowFilterApplying();

            const date = event.date;
            if (date) {
                this.filterOptions.startMonth = date.getMonth() + 1;
                this.filterOptions.startYear = date.getFullYear();
                $("#end_date").datepicker("setStartDate", date);
            } else {
                this.filterOptions.startMonth = null;
                this.filterOptions.startYear = null;
            }
        });

        $("#end_date").datepicker().off("changeDate").on("changeDate", (event) => {
            this.allowFilterApplying();

            const date = event.date;
            if (date) {
                this.filterOptions.endMonth = date.getMonth() + 1;
                this.filterOptions.endYear = date.getFullYear();
                $("#start_date").datepicker("setEndDate", date);
            } else {
                this.filterOptions.endMonth = null;
                this.filterOptions.endYear = null;
            }
        });
    }

    allowFilterApplying() {
        $("#filter_submit").removeAttr("disabled");
    }

    onFilterSubmit() {
        const params = this.convertToFilterParams(this.filterOptions);
        window.location.search = params.toString();
    }

    updateCategoryFilter(id, name) {
        this.allowFilterApplying();

        const categoryId = this.filterOptions.categories[0];

        if (categoryId !== id) {
            cookies.create("activity_preference", id);
            this.filterOptions.categories = id !== 0 ? [id] : [];
            $("#filter-activity-btn").text(name);
        }
    }

    setDefaultValuesToFilters() {
        $("#filter_submit").attr("disabled", "true");

        this.filterOptions = this.getFilterParams();
        const params = this.filterOptions;

        const { features, tags, startMonth, startYear, endMonth, endYear } = params;

        if (features.length) {
            const featuresSelect = $("#filter_track_features");
            $.ajax({
                type: "GET",
                url: window.application.getAPIUrl() + "/v1/users/" + this.userId + "/search_features",
                data: {
                    search: features
                },
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            }).then(function (data) {
                if (data.results !== undefined) {
                    data.results.forEach((item) => {
                        let option = new Option(item.text, item.id, true, true);
                        featuresSelect.append(option).trigger("change");
                    })
                    this.filterOptions.features = featuresSelect.select2("data").map(a => a.id);
                    $("#collapseFilters").collapse("show");
                }
            });
        }

        if (tags.length) {
            let tagsSelect = $("#filter_track_tags");
            $.ajax({
                type: "GET",
                url: window.application.getAPIUrl() + "/v1/users/" + this.userId + "/search_tags",
                data: {
                    search: tags
                },
                beforeSend: function (request) {
                    request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                },
            }).then((data) => {
                if (data.results !== undefined) {
                    data.results.forEach(function (item) {
                        let option = new Option(item.text, item.id, true, true);
                        tagsSelect.append(option).trigger("change");
                    })
                    $("#filter_track_tags").trigger({
                        type: "select2:select",
                        params: {
                            data: data
                        }
                    });
                    this.filterOptions.tags = $("#filter_track_tags").select2("data").map(a => a.id);
                    $("#collapseFilters").collapse("show");
                }
            });
        }

        $("#start_date").datepicker("update", null);
        if (startMonth && startYear) {
            this.filterOptions.startMonth = startMonth;
            this.filterOptions.startYear = startYear;

            $("#start_date").datepicker("update", new Date(startYear, startMonth - 1));
            $("#collapseFilters").collapse("show");
        }

        $("#end_date").datepicker("update", null);
        if (endMonth && endYear) {
            this.filterOptions.endMonth = endMonth;
            this.filterOptions.endYear = endYear;

            $("#end_date").datepicker("update", new Date(endYear, endMonth - 1));
            $("#collapseFilters").collapse("show");
        }
    }

    initSelect2() {
        const theme = "bootstrap4";
        const language = I18n.locale;
        const delay = 250;

        const dataFn = (params) => {
            return {
                search: params.term,
                page: params.page || 1
            };
        };

        const beforeSend = (request) => {
            request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
        };

        const transport = (params, success, failure) => {
            const $request = $.ajax(params);
            $request.then(success);
            $request.fail(failure);

            return $request;
        };

        const processResults = (data, params) => {
            params.page = params.page || 1;
            return {
                results: $.map(data.results, (obj) => {
                    return {
                        "id": obj.id,
                        "text": obj.text
                    };
                }),
                pagination: {
                    more: (params.page * 10) < data.count_filtered
                }
            };
        };

        const getAjaxOptions = (url) => ({
            delay,
            url,
            data: dataFn,
            beforeSend,
            processResults,
            transport
        });

        $(".js-select2-multiple-tags-filter").select2({
            theme,
            language,
            ajax: getAjaxOptions(window.application.getAPIUrl() + "/v1/users/" + this.userId + "/tags")
        });

        $(".js-select2-multiple-features-filter").select2({
            theme,
            language,
            tokenSeparators: [","],
            ajax: getAjaxOptions(window.application.getAPIUrl() + "/v1/users/" + this.userId + "/features")
        });
    }

    initDatepickers() {
        const defaultOptions = {
            format: "mm/yyyy",
            clearBtn: true,
            autoclose: true,
            minViewMode: 1,
            maxViewMode: 2,
            startView: 1,
            disableTouchKeyboard: true,
        }

        $("#start_date").datepicker(defaultOptions);
        $("#end_date").datepicker(defaultOptions);
    }

    getFilterParams() {
        const params = new URLSearchParams(window.location.search);

        return Object.entries(FILTER_KEYS).reduce((result, [key, paramKey]) => {
            if (paramKey.search(/\[\]/) !== -1) {
                result[key] = params.getAll(paramKey);
            } else {
                result[key] = params.get(paramKey) ?? null;
            }

            return result;
        }, {});
    }

    convertToFilterParams(obj) {
        const params = new URLSearchParams();

        Object.entries(obj).forEach(([key, paramValue]) => {
            const paramKey = FILTER_KEYS[key];

            if (paramKey) {
                if (Array.isArray(paramValue)) {
                    paramValue.length && paramValue.forEach((value) => params.append(paramKey, value));
                } else {
                    paramValue && params.set(paramKey, paramValue);
                }
            }
        });

        return params;
    }
}