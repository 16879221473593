import CesiumManagerWrapper from "../utils/CesiumManagerWrapper";
import TrackDetail from "../views/TrackDetail.js"

export default class TrackShowMap {
    constructor() {
        this.trackDetail = new TrackDetail();
        window.application.setOnDataChangeListener(this);
    }

    onDataChanged(data) {
        this.trackDetail.onDataChanged(data);
    }

    onDestroy() {
        if (data.mode == "2D") {
            this.trackDetail.onDestroy();
        }
        if (data.mode == "3D") {
            CesiumManagerWrapper.destroyWidget();
        }
    }
}