import ToastNotification from "./ToastNotification";
import ConfirmationModal from "../../common/ConfirmationModal";

export default class CardManager {

    constructor() {
        this.confirmationModal = new ConfirmationModal();
        this.countries = [];
        this.card_template = "config"
        this.stripe = null;
        this.cardNumber = null;
        this.cardExpiry = null;
        this.cardCvc = null;
        this.validation = null;
        this.loader_url = null
        this.loader_white_url = null
        this.style = {
            base: {
                iconColor: '#23B4F1',
                color: '#1D1F25',
                fontWeight: 400,
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#1D1F25',
                },
                '::placeholder': {
                    color: '#979797',
                },
            }
        };
        this.validation_options = {
            rules:{
                cc_name: {
                    required: true
                },
                country: {
                    required: true
                }
            }
        }

        if(I18n.locale !== 'en'){
            this.validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale)
        }
        this.bindEvents();
    }


    bindEvents() {
        let self = this;

        $("#add_card").unbind("click").click(function (e) {
            e.preventDefault();
            self.newCardModal();
        })

        this.bindActionEvents();
    }

    onDataChanged(data) {
        this.stripe = Stripe(data.stripe_public_key);
        this.loader_url = data.loader_url;
        this.loader_white_url = data.loader_white_url;
        this.countries = data.countries;
        if(data.card_template !== undefined && data.card_template !== null){
            this.card_template = data.card_template
        }
    }

    onDestroy() {
        window.$('#js-country-select').select2('destroy');
    }

    newCardModal() {

        let ajaxData = {
            url: application.getAPIUrl()+'/v1/stripe/get_setup_intent',
            method: "get",
            data: {},
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        $.ajax(ajaxData).done(function(data) {
            self.client_secret = data.client_secret
        }).fail(function(err) {
            //self.cardError(err.message);
        });

        let self = this;
        $(document.body).append(this.adCardModal());
        $('#create_card_modal').modal({keyboard: false,backdrop: 'static'});
        $('#create_card_modal').off("hidden.bs.modal").on("hidden.bs.modal", function (e) {
            $('#create_card_modal').remove();
        })

        window.$('#js-card-country-select').select2({
            theme: 'bootstrap4',
            templateResult: self.formatCountry,
            templateSelection: self.formatCountry,
            language: I18n.locale
        });

        $('b[role="presentation"]').hide();
        $('.select2-selection__arrow').append('<i class="fas fa-chevron-down fs-12"></i>');
        $('.select2-selection__arrow').addClass("custom_arrow_select2");

        self.initStripe();

        self.validation = $("#new_card_form").validate(this.validation_options);

        $('input[name=cc_name]').keydown(function (e) {
            if (e.keyCode === 13) {
                $("form#new_card_form").submit();
                return false;
            }
        })

        this.cardNumber.on('change', function(event) {
            let displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        this.cardExpiry.on('change', function(event) {
            let displayError = document.getElementById('cardExpiry-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        this.cardCvc.on('change', function(event) {
            let displayError = document.getElementById('cardCvc-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        $("form#new_card_form").on('submit', function(e) {
            e.preventDefault();

            $('#create_card_modal .modal-body').append('<div id="modal_loader" class="row position-absolute w-100 h-100 bg-white" style="top: 0"><div class="col-4 offset-4"> <img class="img-fluid" src="'+ self.loader_url +'"></div> </div>');
            $("#create_card").prop('disabled', true);
            $('#create_card').html('<img height="20" src="'+ self.loader_white_url +'"> Sauvegarde de la carte en cours');
            if(self.validation.valid()){

                self.stripe.confirmCardSetup(
                    self.client_secret,
                    {
                        payment_method: {
                            card: self.cardNumber,
                            billing_details: {
                                name: $("input[name=cc_name]").val(),
                            },
                        },
                    }
                ).then(function(result) {
                    if (result.error) {
                        self.errorCard(result.error);
                    } else {

                        let ajaxData = {
                            url: application.getAPIUrl()+'/v1/stripe/create_payment_method',
                            method: "post",
                            data: {si_id: result.setupIntent.id},
                            beforeSend: function (request) {
                                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
                            },
                        };

                        $.ajax(ajaxData).done(function(data) {
                            $('#card_container').append(self.newCardElement(data));
                            self.bindActionEvents();
                            ToastNotification.make('Card added successfully');
                            $("#create_card_modal").modal('hide');

                        }).fail(function(err) {
                            self.errorCard();
                        });
                    }
                });
            }else{
                self.errorCard();
            }
        });
    }

    adCardModal(){
        let modal = '  <div id="create_card_modal" class="modal fade" role="dialog">\n' +
            '    <div class="modal-dialog modal-lg">\n' +
            '\n' +
            '      <!-- Modal content-->\n' +
            '      <div class="modal-content">\n' +
            '        <div class="modal-header py-1 bg-dark-light">\n' +
            '          <h4 class="modal-title text-white fs-16"><img height="36" class="mr-3" src="'+window.badge_outline_url+'">\n' +
            '            '+ I18n.t("website.checkout.index.add_card") +' \n' +
            '          </h4>\n' +
            '          <a class="close text-white" style="margin-top: -13px;opacity: 1;font-size: 30px;" data-dismiss="modal"><i class="mdi mdi-close"></i> </a>\n' +
            '        </div>\n' +
            '<form id="new_card_form">' +
            '        <div class="modal-body">\n' +
            '           <div class="row">' +
            '             <div class="col-12 col-md-6 mb-3">\n' +
            '                <label class="fs-14">'+ I18n.t("website.checkout.index.name_card") +'</label>\n' +
            '                <input class="form-control form-control-lg fs-16" autocomplete="cc-name" name="cc_name" style="height: 53px;">\n' +
            '              </div>\n' +
            '\n' +
            '              <div class="col-12 col-md-6 mb-3">\n' +
            '                <label class="fs-14">'+ I18n.t("website.checkout.index.card") +'</label>\n' +
            '                <div class="border border-lighter-gray rounded p-3" id="card_container">\n' +
            '                  <div id="cardNumber"></div>\n' +
            '                </div>\n' +
            '                <div id="card-errors" role="alert" class="text-danger fs-14"></div>\n' +
            '              </div>\n' +
            '\n' +
            '              <div class="col-7 col-sm-8 col-md-4 mb-3">\n' +
            '                <label class="fs-14">'+ I18n.t("website.checkout.index.card_expiry") +'</label>\n' +
            '                <div class="border border-lighter-gray rounded p-3" id="cardExpiry_container">\n' +
            '                  <div id="cardExpiry"></div>\n' +
            '                </div>\n' +
            '                <div id="cardExpiry-errors" role="alert" class="text-danger fs-14"></div>\n' +
            '              </div>\n' +
            '\n' +
            '              <div class="col-5 col-sm-4 col-md-2 mb-3">\n' +
            '                <label class="fs-14">'+ I18n.t("website.checkout.index.card_cvc") +'</label>\n' +
            '                <div class="border border-lighter-gray rounded p-3" id="cardCvc_container">\n' +
            '                  <div id="cardCvc"></div>\n' +
            '                </div>\n' +
            '                <div id="cardCvc-errors" role="alert" class="text-danger fs-14"></div>\n' +
            '              </div>\n' +
            '\n' +
            '              <div class="col-12 col-md-6 select-country-checkout">\n' +
            '                <label class="fs-14">'+ I18n.t("website.checkout.index.billing_country") +'</label>\n' +
            '                <select id="js-card-country-select" class="form-control" name="country">\n';

                this.countries.forEach(function (country) {
                    modal+= '<option value="'+country.id+'" '+(cookies.get('country_code') === country.code ? "selected" : "")+' data-country-code="'+country.code+'">'+country.hr_name+'</option>\n';
                });

            modal += '                </select>\n' +
            '              </div>' +
            '           </div>' +
            '        </div>\n' +
            '        <div class="modal-footer py-2 mt-4">\n' +
            '          <button class="btn btn-dark-light py-2 px-3 font-weight-bolder fs-14" data-dismiss="modal">'+I18n.t("website.checkout.index.cancel")+'</button>\n' +
            '          <button id="create_card"  type="submit" class="btn btn-secondary py-2 px-3 font-weight-bolder fs-14">'+I18n.t("website.checkout.index.validate")+'</button>\n' +
            '        </div>\n' +
                '</form>' +
            '      </div>\n' +
            '    </div>\n' +
            '  </div>\n';

        return modal;
    }

    newCardElement(card){
        if(this.card_template === "config"){
            return '<div id="card_'+card.id+'" data-card-id="'+card.id+'" class="col-12 mb-2">\n' +
                '                    <div class="card bg-light">\n' +
                '                      <div class="card-body">\n' +
                '                        <div class="row fs-14">\n' +
                '                          <div class="col-12 col-md-3 my-auto">\n' +
                '                            '+card.card_name+'\n' +
                '                          </div>\n' +
                '                          <div class="col-12 col-md-4 my-auto">\n' +
                '                            XXXX-XXXX-XXXX-'+card.last_four+'\n' +
                '                          </div>\n' +
                '                          <div class="col-12 col-md-2 my-auto text-center">\n' +
                '                            '+card.expire_month+'/'+card.expire_year+'\n' +
                '                          </div>\n' +
                '                          <div class="col-12 col-md-3 my-auto text-center">\n' +
                '                              <span class="badge-pill badge-secondary py-1 if_selected">In use</span>\n' +
                '                              <button class="btn btn-secondary js-swap-default-card if_not_selected" data-toggle="tooltip" data-card-id="'+card.id+'" data-placement="top" title="Set as default card"><i class="fas fa-exchange-alt"></i></button>\n' +
                '                              <button class="btn btn-danger if_not_selected js-delete-card" data-toggle="tooltip" data-placement="top" data-card-id="'+card.id+'" title="Tooltip on top"><i class="fas fa-trash-alt"></i> </button>\n' +
                '                          </div>\n' +
                '                        </div>\n' +
                '                      </div>\n' +
                '                    </div>\n' +
                '                  </div>';
        }else{
            return '<div  id="card_'+card.id+'" data-card-id="'+card.id+'" class="col-12 mb-2">\n' +
                '                    <div class="card bg-light">\n' +
                '                      <label class="mb-0 cursor-pointer" for="card_select_'+card.id+'">\n' +
                '                      <div class="card-body">\n' +
                '                        <div class="row fs-14">\n' +
                '                          <div class="col-12 col-md-3 my-auto">\n' +
                '                            '+card.card_name+'\n' +
                '                          </div>\n' +
                '                          <div class="col-12 col-md-4 my-auto">\n' +
                '                            XXXX-XXXX-XXXX-'+card.last_four +'\n' +
                '                          </div>\n' +
                '                          <div class="col-12 col-md-2 my-auto text-center">\n' +
                '                            '+card.expire_month+'/'+card.expire_year+'\n' +
                '                          </div>\n' +
                '                          <div class="col-12 col-md-3 my-auto text-center">\n' +
                '                            <div class="form-check form-check-inline form-control-lg custom_radio">\n' +
                '                                <input class="form-check-input" id="card_select_'+card.id+'" type="radio" class="" name="payment_method" value="'+card.payment_method_id+'">\n' +
                '                                <span class="checkmark"></span>\n' +
                '                            </div>\n' +
                '                          </div>\n' +
                '                        </div>\n' +
                '                      </div>\n' +
                '                      </label>\n' +
                '                    </div>\n' +
                '                  </div>';
        }
    }

    formatCountry(act) {
        let $act = "";
        if (!act.id) {
            return act.name;
        }else if($(act.element).val() === "0"){
            $act = $(
                '<span class="lh-7 fs-16 px-2">' + act.text + '</span>'
            );
        }else{
            $act = $(
                '<span class="lh-7 fs-16">\n<span class="mx-2 rounded flag-icon flag-icon-'+ $(act.element).data("country-code")  +'"></span>' + act.text + '</span>'
            );
        }
        return $act;
    };

    initStripe(){
        this.elements = this.stripe.elements({locale: I18n.locale});
        this.cardNumber = this.elements.create('cardNumber',{style: this.style});
        this.cardNumber.mount('#cardNumber');
        this.cardExpiry = this.elements.create('cardExpiry',{style: this.style});
        this.cardExpiry.mount('#cardExpiry');
        this.cardCvc = this.elements.create('cardCvc',{style: this.style});
        this.cardCvc.mount('#cardCvc');
    }

    swapDefaultCard(card_id) {
        let ajaxData = {
            url: application.getAPIUrl()+'/v1/stripe/set_default_pm',
            method: "put",
            data: {card_id: card_id},
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        $.ajax(ajaxData).done(function(data) {
            $('div[id^=card_]').each(function () {
                if(parseInt($(this).data("card-id")) !== data.id){
                    $(this).removeClass("selected_card");
                }else{
                    $(this).addClass("selected_card");
                }
            })
            ToastNotification.make("Change completed");
        }).fail(function(err) {
            ToastNotification.make("Errors happens");
        });
    }

    errorCard(error = null) {
        $('#modal_loader').remove();
        $("#create_card").prop('disabled', false);
        $('#create_card').html('validate');
        if(error !== null){
            switch (error.code) {
                case 'incomplete_number' || 'invalid_number':
                    $('#card').addClass('border-danger');
                    $('#card-errors').text(error.message);
                    break;
                case 'incomplete_expiry' || 'invalid_expiry_month_past' || 'invalid_expiry_year_past':
                    $('#cardExpiry').addClass('border-danger');
                    $('#cardExpiry-errors').text(error.message)
                    break;
                case 'incorrect_cvc':
                    $('#cardCvc').addClass('border-danger');
                    $('#cardCvc-errors').text(error.message)
                    break;
                default:
                    break;
            }
        }else{
            $('#card').text("")
            $('#cardExpiry').text("")
            $('#cardCvc-errors').text("")
        }
    }

    bindActionEvents() {
        let self = this;

        $('.js-swap-default-card').unbind("click").click(function (e) {
            e.preventDefault();
            self.swapDefaultCard($(this).data("card-id"));
        })

        $('.js-delete-card').unbind("click").click(function (e) {
            e.preventDefault();
            let element = this;
            self.confirmationModal.displayConfirm(I18n.t("website.configuration.delete_card.title"),
                I18n.t("website.configuration.delete_card.message"),
                {
                    positive_btn: {
                        type: "danger",
                        label: I18n.t("website.configuration.delete_card.positive_btn"),
                        callback: function() {
                            self.deleteCard($(element).data("card-id"));
                        }
                    },
                    negative_btn: {
                        label: I18n.t("website.configuration.delete_card.negative_btn")
                    }
                });
        })
    }

    deleteCard(card_id) {
        let ajaxData = {
            url: application.getAPIUrl()+'/v1/stripe/delete_payment_method',
            method: "delete",
            data: {card_id: card_id},
            beforeSend: function (request) {
                request.setRequestHeader("X-STL-Token", cookies.get("STL-Token"));
            },
        };

        $.ajax(ajaxData).done(function(data) {
            $("#card_"+data.id).remove();
            ToastNotification.make("Card deleted");
        }).fail(function(err) {
            ToastNotification.make(err.responseJSON.message);
        });
    }
}