import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton/src/easy-button";
import MeasureConversion from "../utils/MeasureConversion";
import { TrackerMarkerManager } from "../utils/TrackerMarkerManager";
import { TrackMarkerManager } from "../utils/TrackMarkerManager";

export default class HomeLiveMap {
  constructor() {
    let self = this;
    this.map = null;
    this.logo_icon = null;
    this.logo_text = null;
    this.bounds = null;
    this.category_id = 0;
    this.tracks_from = cookies.get("live_from")
      ? parseInt(cookies.get("live_from"))
      : 0;
    this.fatherClass = null;

    this.doit = null;

    this.user_position_lat = 0;
    this.user_position_lng = 0;

    if (navigator.geolocation) {
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(this.set_position);
      }, 2000);
    }

    this.marker_pointer = "";
    this.marker_pointer_live = "";
    this.marker_pointer_tracker = "";
    this.marker_pointer_shadow = "";
    this.btn1 = null;

    this.measureConversion = null;

    this.is_spiderfied = false;
    this.last_selected_markers = [];
  
    this.markerClusterGroup = L.markerClusterGroup({
      animate: true,
      spiderfyDistanceMultiplier: 1.5,
      spiderLegPolylineOptions: { weight: 1.5, color: "#222", opacity: 0.3 },
      iconCreateFunction: function (cluster) {
        let markers = cluster.getAllChildMarkers();
        let isLive = false;
        let isTracker = false;
        let icon_url = "";
        let icon_class = "";
        for (var i = 0; i < markers.length; i++) {
          if (markers[i].options.icon.options.className == "is_live") {
            isLive = true;
            break;
          } else if (
            markers[i].options.icon.options.className == "is_tracker"
          ) {
            isTracker = true;
            break;
          }
        }
        if (isLive) {
          icon_url = self.marker_pointer_live;
          icon_class = "text-danger";
        } else if (isTracker) {
          icon_url = self.marker_pointer_tracker;
          icon_class = "text-danger";
        } else {
          icon_url = self.marker_pointer;
        }
        return L.divIcon({
          html:
            '<img src="' +
            icon_url +
            '" style="margin-top:-28px;height: 59px;width: 47px;"><div class="' +
            icon_class +
            ' position-absolute w-100 text-center text-secondary fw-600 fs-16" style="top:-16px;left: 0;">' +
            cluster.getChildCount() +
            "</div>",
          className: "liveCluster",
          iconSize: L.point(47, 59),
        });
      },
    });

    this.trackerMarkerManager = null;
    this.trackMarkerManager = null;

    this.initMap();
  }

  bindEvents() {
    let self = this;
    this.map.on("layeradd", function () {
      window.$('[data-toggle="tooltip"]').tlp();
      self.fatherClass.live_btn_listener();
    });

    this.markerClusterGroup.on("spiderfied", function (a) {
      self.is_spiderfied = true;
    });

    this.markerClusterGroup.on("unspiderfied", function (a) {
      self.is_spiderfied = false;
    });

    $(window).resize(function () {
      clearTimeout(self.doit);
      self.doit = setTimeout(function () {
        if ($(window).width() < 767) {
          $("#home-live-map").height("400px");
        } else {
          $("#home-live-map").height("100%");
        }
        self.map.invalidateSize();
      }, 200);
    });
    $(window).on("scroll", function () {
      $("#full-toggle").tlp().mouseover();
      setTimeout(function () {
        $("#full-toggle").tlp("hide");
      }, 5000);
    });
    $(document).on(
      "webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange",
      function () {
        if (
          !(
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
          )
        ) {
          $("#live-sidebar").addClass("pl-3");
          $("#live-sidebar").addClass("pl-lg-5");
          $("#live-sidebar-scroll").css("height", "");
          $("#live-map-container").addClass("pr-lg-5");
          $("#live-map-container").removeClass("pr-0");
          $("#home-live-map").removeClass("fullscreen_map");
          $("#live-standings-container").addClass("pl-0");
          $("#live-standings-container").removeClass("px-0");
          $("#full-icon").addClass("fa-expand");
          $("#full-icon").removeClass("fa-compress");
          $(".logos").addClass("d-none");
        } else {
          $("#live-sidebar").removeClass("pl-3");
          $("#live-sidebar").removeClass("pl-lg-5");
          $("#live-sidebar-scroll").css("height", "100vh");
          $("#live-map-container").removeClass("pr-lg-5");
          $("#live-map-container").addClass("pr-0");
          $("#home-live-map").addClass("fullscreen_map");
          $("#live-standings-container").removeClass("pl-0");
          $("#live-standings-container").addClass("px-0");
          $("#full-icon").removeClass("fa-expand");
          $("#full-icon").addClass("fa-compress");
          $(".logos").removeClass("d-none");
        }
      }
    );

    $("#live-count-container")
      .off("click")
      .on("click", (e) => {
        e.preventDefault();
        this.center_to_lives();
      });
  }

  onDataChanged(data) {
    this.measureConversion = new MeasureConversion(data.user_unit_system);

    this.logo_icon = data.logo_icon;
    this.category_id = data.category_id;
    this.logo_text = data.logo_text;
    this.marker_pointer = data.marker_pointer;
    this.marker_pointer_live = data.marker_pointer_live;
    this.marker_pointer_shadow = data.marker_pointer_shadow;

    this.marker_pointer_tracker = data.marker_pointer_tracker;
    
    this.initMarkerManagers();

    this.map.addLayer(this.markerClusterGroup);
    this.loadMapTracks();
    this.bindEvents();

    let logo = L.control({ position: "bottomright" });
    logo.onAdd = () => {
      const div = L.DomUtil.create("div", "logos d-none");
      div.innerHTML = `<img height="200" src="${this.logo_icon}"/>`;

      return div;
    };
    logo.addTo(this.map);
  }

  onDestroy() {
    this.map.remove();
  }

  initMarkerManagers() {
    this.trackerMarkerManager = new TrackerMarkerManager(
      this,
      this.markerClusterGroup, 
      this.marker_icon_html, 
    );
    this.trackMarkerManager = new TrackMarkerManager(
      this,
      this.markerClusterGroup,
      this.marker_icon_html,
      { measureConversion: this.measureConversion }
    );

    this.trackMarkerManager.setMarkerPointerTracker(this.marker_pointer, this.marker_pointer_live, this.marker_pointer_shadow);
    this.trackerMarkerManager.setMarkerPointerTracker(this.marker_pointer_tracker);
  }

  initMap() {
    const height = Math.max($("#home-live-map").parent().height(), 400);
    $("#home-live-map").height(height);

    const bounds = new L.LatLngBounds(
      new L.LatLng(85, 180),
      new L.LatLng(-85, -180)
    );

    this.map = L.map("home-live-map", {
      attributionControl: false,
      maxBounds: bounds,
      maxBoundsViscosity: 1,
      minZoom: 2,
    }).setView([41.831131, 15.624207], 2);

    L.tileLayer("https://tile.tracestrack.com/_/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369", {
      attribution: "&copy; SportsTrackLive",
    }).addTo(this.map);
    L.control
      .attribution({
        position: "bottomleft",
      })
      .addTo(this.map);

    const follow_btn = L.easyButton(
      '<span class="fs-18 mt-1 text-gray ff-lato"><i class="mdi mdi-target"></i></span>',
      () => {
        const selectedMarkerModels = this.trackMarkerManager.selectedMarkers
          .concat(this.trackerMarkerManager.selectedMarkers);

        if (selectedMarkerModels.length > 0) {
          const selectedMarkers = selectedMarkerModels.map(({marker}) => marker);
          const group_m = new L.featureGroup(selectedMarkers);
          const zoom = Math.max(this.map.getZoom(), 11);

          this.map.flyToBounds(group_m.getBounds(), {
            duration: 0.5,
            padding: [50, 50],
            maxZoom: zoom,
          });
        } else if (this.user_marker != null) {
          this.map.setView(this.user_marker.getLatLng(), 10);
        }
      }
    );

    const full_screen_btn = L.easyButton(
      '<span class="fs-18 mt-1 text-gray ff-lato" id="full-toggle" data-toggle="tooltip" data-placement="bottom" title="' +
        I18n.t("website.home.index.full_screen") +
        '"><i id="full-icon" class="fas fa-expand"></i></span>',
      () => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
        ) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          let element = $("#live-container").get(0);
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
          }
        }
      }
    );

    this.btn1 = L.easyBar([full_screen_btn], { position: "topleft" });
    this.btn2 = L.easyBar([follow_btn], { position: "topleft" });
    this.btn1.addTo(this.map);
    this.btn2.addTo(this.map);

    if (cookies.get("home_map_lat") !== null) {
      this.map.setView(
        [cookies.get("home_map_lat"), cookies.get("home_map_lng")],
        cookies.get("home_map_zoom")
      );
    }
  }

  marker_icon_html(icon_url, item) {
    return '<img src="' +
    icon_url +
    '" style="margin-top:-28px;height: 59px;width: 47px;"><div class="position-absolute w-100 text-center text-secondary fw-600 fs-16" style="top:-20px;left: 0;"><img class="rounded-circle" height="31" src="' +
    item.user_profile_image +
    '"></div>';
  } 

  updateMapTracks() {
    if (!this.is_spiderfied) {
      this.loadMapTracks();
    } else {
      this.markerClusterGroup.off("unspiderfied");
      this.markerClusterGroup.on("unspiderfied", (a) => {
        this.is_spiderfied = false;
        this.loadMapTracks();

        this.markerClusterGroup.off("unspiderfied");
        this.markerClusterGroup.on("unspiderfied", (a) => {
          this.is_spiderfied = false;
        });
      });
    }
  }

  loadMapTracks() {
    const bounds = this.map.getBounds();

    this.bounds = {
      sw_lat: bounds._southWest.lat,
      sw_lng: bounds._southWest.lng,
      ne_lat: bounds._northEast.lat,
      ne_lng: bounds._northEast.lng
    }

    this.trackMarkerManager.fetchData(this.bounds, this.category_id, this.tracks_from);
    this.trackerMarkerManager.fetchData(this.bounds);
  }

  updateCategory(category_id) {
    this.is_spiderfied = false;
    this.category_id = category_id;
    this.loadMapTracks();
  }

  updateEventsFrom(from) {
    this.is_spiderfied = false;
    this.tracks_from = from;
    this.loadMapTracks();
  }

  setFatherClass(father) {
    this.fatherClass = father;
  }

  disable_track_live(tid, selected_tracks) {
    this.trackMarkerManager.disableLive(tid, selected_tracks);
    this.trackerMarkerManager.disableLive(tid, selected_tracks);
  }

  enable_track_live(tid, selected_tracks, uid) {
    this.last_selected_markers.push(tid);

    this.trackMarkerManager.enableLive(tid, uid);
    this.trackerMarkerManager.enableLive(tid, uid);
  }

  center_to(tid, is_live = false) {
    this.trackMarkerManager.centerToMarker(tid);
    this.trackerMarkerManager.centerToMarker(tid);
  }

  update_live_markers(data) {
    this.trackMarkerManager.updateLiveMarker(data);
    this.trackerMarkerManager.updateLiveMarker(data);
  }

  set_position(position) {
    let self = window.application.current_handler.homeLiveMap;

    let marker_icon = function () {
      return L.divIcon({
        html:
          '<img src="' +
          self.marker_pointer +
          '" style="margin-top:-28px;height: 59px;width: 47px;"><div class="position-absolute w-100 text-center text-secondary fw-600 fs-16" style="top:-17px;left: 0;"><span class="fas fa-user"></span></div>',
        shadowUrl: self.marker_pointer_shadow,
        className: "no_live",
        iconSize: [47, 59], // size of the icon 78*114
        shadowSize: [59, 33], // size of the shadow
        shadowAnchor: [0, 30], // the same for the shadow
      });
    };

    self.user_marker = new L.marker(
      [position.coords.latitude, position.coords.longitude],
      { icon: marker_icon() }
    );
    self.user_marker.addTo(self.map);

    if (cookies.get("home_map_lat") === null) {
      self.map.setView(self.user_marker.getLatLng(), 10);
    }
  }

  live_ended(tid) {
    $("#tooltip_live_follow_" + tid).addClass("d-none");
    $("#tooltip_live_2d_" + tid).addClass("d-none");
    $("#tooltip_live_finish_" + tid).removeClass("d-none");
  }

  center_to_lives() {
    window.scrollTo({
      left: 0,
      top: $("#live-filters").offset().top - 60,
      behavior: "smooth",
    });

    const allLiveMarkerModels = this.trackMarkerManager.liveMarkers.concat(this.trackerMarkerManager.liveMarkers);
    if (allLiveMarkerModels.length > 0) {
      let live_markers = allLiveMarkerModels.map(({marker}) => marker);

      let group_m = new L.featureGroup(live_markers);
      this.map.flyToBounds(group_m.getBounds(), {
        duration: 0.5,
        padding: [50, 50],
      });
    }
  }
}
