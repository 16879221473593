import { globalState, setGlobalState, fnSwapList } from './Context';

class SphereAnimated {

	constructor(canvasId, backgroundImages) {
		this.forceStop = false;
		this.stopped = false;
		this.canvasId = canvasId;
		this.backgroundImages = backgroundImages;
		this.currentBackgroundIndex = 0;
		this.transitionSpeed = 0.007;
		this.currentTransition = 0.0;
		this.transitioning = false;
		this.easedTransition = 0.0;
		this.backgroundImage = new Image();
		this.backgroundImage.src = this.backgroundImages[this.currentBackgroundIndex];
		this.nextBackgroundImage = new Image();
		this.preloadedBackgrounds = this.backgroundImages.map(src => {
			const img = new Image();
			img.src = src;
			return img;
		});

		this.slider = document.querySelector('.slider')
		this.slidingIndex = 1

		
		this.fnInitSphere(canvasId);
		this.addItemsClickListeners();
		
		const onScroll = (scrollLength) => {
			if (scrollLength < window.innerHeight/1.5) {
				if(this.stopped){
					if(this.forceStop) return;
					this.stopped = false;
					this.fnInitSphere(canvasId);
				}
			} else {
				if(!this.stopped){
					this.stopped = true;

				}
			}
		};
		
		window.addEventListener('scroll', () => {
			onScroll(window.scrollY);
		});
	}

	stopAnimation(){
		this.forceStop = true;
		this.stopped = true;
	}

	startAnimation(){
		this.forceStop = false;
		this.stopped = false;
		this.fnInitSphere(this.canvasId);
	}

	easeInOutCubic(t) {
		return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
	}

	startBackgroundTransition(targetIndex = null) {
		console.log(targetIndex, this.currentBackgroundIndex, this.slidingIndex)
		const items = document.querySelectorAll('.item');
		if (items.length < 2) {
			return;
		}
	
		let steps = 0;
		if (targetIndex !== null) {
			if(targetIndex === this.slidingIndex) return;
			steps = targetIndex - this.currentBackgroundIndex - 1;
			if (steps < 0) {
				steps += this.backgroundImages.length;
			}
		} else {
			steps = 1;
		}
	
		this.currentBackgroundIndex = (this.currentBackgroundIndex + steps) % this.backgroundImages.length;
		this.nextBackgroundImage = this.preloadedBackgrounds[this.currentBackgroundIndex];
	
		this.addAllBackgrounds();
		this.removeBackground(this.currentBackgroundIndex + 1);
	
		for(let i = 1; i < steps + 1; i++){
			this.slider.append(items[i]);

			// Uncomment the following lines to animate the slider append (has a side effect of changing the order of the items in the DOM)
			// setTimeout(() => {
			// 	this.slider.append(items[i]);
			// }, i * 100);
		}
		
		this.slidingIndex = targetIndex !== null ? targetIndex : (this.slidingIndex + 1) % items.length;
	
		this.currentTransition = 0.0;
		this.transitioning = true;
	}
  
	updateBackgroundTransition() {
		if (!this.transitioning) return;
  
		this.currentTransition += this.transitionSpeed * 2;
		if (this.currentTransition > 1.0) this.currentTransition = 1.0;
		this.easedTransition = this.easeInOutCubic(this.currentTransition);
		if (this.currentTransition >= 1.0) {
			this.transitioning = false;
			this.backgroundImage = this.nextBackgroundImage;
		}
	}
  
	drawBackground(ctx, w, h) {

		const calculateCoverImageDimensions = (img, w, h) => {
			let imgRatio = img.width / img.height;
			let canvasRatio = w / h;
			let renderW, renderH, startX, startY;
	  
			if (imgRatio < canvasRatio) {
				renderW = w;
				renderH = w / imgRatio;
				startX = 0;
				startY = (h - renderH) / 2;
			} else {
				renderW = h * imgRatio;
				renderH = h;
				startX = (w - renderW) / 2;
				startY = 0;
			}
	  
			return { renderW, renderH, startX, startY };
		}
	  
		if (!this.transitioning) {
			let { renderW, renderH, startX, startY } = calculateCoverImageDimensions(this.backgroundImage, w, h);
			ctx.drawImage(this.backgroundImage, startX, startY, renderW, renderH);
		} else {
			this.currentTransition += this.transitionSpeed;
			if (this.currentTransition >= 1.0) {
				this.transitioning = false;
				this.currentTransition = 1.0;
				this.backgroundImage = this.nextBackgroundImage; 
			}
	  
			let slideOffset = w * this.easedTransition;
			
			// Draw current background
			let { renderW: currentRenderW, renderH: currentRenderH, startX: currentStartX, startY: currentStartY } = calculateCoverImageDimensions(this.backgroundImage, w, h);
			ctx.drawImage(this.backgroundImage, currentStartX - slideOffset, currentStartY, currentRenderW, currentRenderH);
			
			// Draw next background
			let { renderW: nextRenderW, renderH: nextRenderH, startX: nextStartX, startY: nextStartY } = calculateCoverImageDimensions(this.nextBackgroundImage, w, h);
			ctx.drawImage(this.nextBackgroundImage, nextStartX + w - slideOffset, nextStartY, nextRenderW, nextRenderH);
		}
	}
  
	fnRequestAnimationFrame(fnCallback){
		if(this.stopped) return;
		const fnAnimFrame =
				window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				(fnCallback => {
					window.setTimeout(fnCallback, 1000 / 60);
				});
		fnAnimFrame(fnCallback);
	};

	fnAddEventListener(o, sEvent, fn) {
		if (o.addEventListener) {
			o.addEventListener(sEvent, fn, false);
		} else {
			o[`on${sEvent}`] = fn;
		}
	}

	fnInitSphere(canvasId) {
		const oDoc = document;
	
		const nCanvasRender = oDoc.getElementById(canvasId);
		const ctxRender = nCanvasRender.getContext('2d');
	
		// Renderer and Buffer objects
		const oRender = { pFirst: null };
		const oBuffer = { pFirst: null };
		
		let w = 0;
		let h = 0;
	
		const fnSetSize = () => {
			nCanvasRender.width = w = window.innerWidth;
			nCanvasRender.height = h = window.innerHeight;
			
			const iProjSphereX = w * 0.95;
			const iProjSphereY = h / 2;
		
			setGlobalState('iProjSphereX', iProjSphereX); // Expose for use in rendering logic
			setGlobalState('iProjSphereY', iProjSphereY);
		
			return { w, h };
		};
	
		fnSetSize();
	
		this.fnAddEventListener(window, 'resize', fnSetSize);
			
		// Rendering logic
		const fnRender = () => {
			this.updateBackgroundTransition();
      		this.drawBackground(ctxRender, w, h);
		
			// Render each particle
			let p = oRender.pFirst;
			while (p) {
				ctxRender.fillStyle = `rgba(${globalState.aColor.join(',')},${p.fAlpha.toFixed(4)})`;
				ctxRender.beginPath();
				ctxRender.arc(p.fProjX, p.fProjY, p.fRadiusCurrent, 0, 2 * Math.PI, false);
				ctxRender.closePath();
				ctxRender.fill();
				p = p.pNext;
			}
		};
			
		const fnNextFrame = () => {
	
			// Update rotation angle
			setGlobalState('fAngle', (globalState.fAngle + globalState.fVX) % (2.0 * Math.PI));
			setGlobalState('fCosAngle', Math.cos(globalState.fAngle));
			setGlobalState('fSinAngle', Math.sin(globalState.fAngle));
		
			// Add new particles
			let iAddParticle = 0;
			while (iAddParticle++ < globalState.iNewParticlePerFrame) {
				const p = fnSwapList(oBuffer.pFirst, oBuffer, oRender);
				p.fnInit();
			}
		
			// Update existing particles
			let p = oRender.pFirst;
			while (p) {
				const pNext = p.pNext;
				p.fnUpdate();
				p = pNext;
			}
		
			fnRender();
			
			this.fnRequestAnimationFrame(fnNextFrame);
		};
	
		fnNextFrame();
	}

	addAllBackgrounds() {
		const items = document.querySelectorAll('.item');
		for (let i = 1; i < items.length; i++) {
			let imageUrl = this.backgroundImages[i-1];
			let item = document.getElementById('ws-slider-item-' + i);
			if (item) {
				item.setAttribute('style', "background-image: url('" + imageUrl + "')");
				item.style.cursor = 'pointer';
			} else {
				console.log('Element not found: ws-slider-item-' + i);
			}
		}
	}

	removeBackground(index) {
        document.getElementById('ws-slider-item-' + index).style.backgroundImage = "none";
    }
	
	addItemsClickListeners() {
		const items = document.querySelectorAll('.item');
		items.forEach((item, index) => {
			item.addEventListener('click', () => {
				this.startBackgroundTransition(index);
			});
			item.style.cursor = 'pointer';
		});
	}
	
}
  

export default SphereAnimated;